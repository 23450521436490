<template>
  <div>
    <v-container id="wrapper" >
      <PlantCarousel/>
      <PlantLogoBanner/>
      <ContentDeck />
      <div class="text-h5 text--primary goodsService" style="padding-bottom: 25px; padding-top:25px; text-align:center;">Our plant subscription here <a href="https://www.goodssj.com/product/plant-lover-subscription/887?cp=true&sa=true&sbp=false&q=false" target="_blank">goodssj</a></div>
    </v-container>
    <MailingList />
  </div> 
</template>

<script>
import PlantCarousel from '../components/PlantCarousel'
import PlantLogoBanner from '../components/PlantLogoBanner'
import ContentDeck from '../components/ContentDeck'
import MailingList from '../components/MailingList'

  export default {
    name: 'Home',
    title: 'Donna Grove',
    components: { 
      PlantCarousel, 
      PlantLogoBanner,
      ContentDeck,
      MailingList
    },
    data: () => {
      return {}
    }
  }
</script>

<style scoped>
  #wrapper {
    padding: 30px 0px;
    max-width: 875px;
  }
  .goodsService {
    max-width: 85%;
    background-color: #DCBD9E;
    border-radius: 7px;
    margin: auto;
  }
</style>
