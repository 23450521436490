<template>
  <div id="image-banner-wrapper">
    <p class="d-none d-xl-flex" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-lg-flex d-xl-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-md-flex d-lg-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-sm-flex d-md-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-flex d-sm-none" id="banner-dashes">.</p>
    <v-container id="image-banner" >
      <v-img :src="require('../assets/FullLogoNoBackground.png')"
              contain/>
    </v-container>
    <p class="d-none d-xl-flex" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-lg-flex d-xl-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-md-flex d-lg-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-none d-sm-flex d-md-none" id="banner-dashes">.&nbsp;&nbsp;&nbsp;.</p>
    <p class="d-flex d-sm-none" id="banner-dashes">.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  #image-banner-wrapper {
    display:flex;
    flex-wrap: nowrap;
    justify-content:center;
    flex-direction: row;
    white-space:nowrap;
  }
  #image-banner {
    width:150px;
    height:125px;
    margin-left:45px;
    margin-right: 46px;
    margin-top:28px;
  }
  #banner-dashes {
    color: #006a2b;
    white-space:nowrap;
    font-size: 75px;
    font-family: 'Roboto'
  }
</style>