<template>
  <div class="wrapper">
      <ContentCard class="card" v-for="(card,i) in cards" 
      :key="i" 
      :titleFrontText="card.titleFrontText" 
      :frontImage="card.frontImage"
      :titleBackText="card.titleBackText" 
      :bodyBackText="card.bodyBackText"      
      />
  </div>
</template>

<script>
import ContentCard from './ContentCard'
export default {
    name: "ContentDeck",
    components: {
        ContentCard
    },
    data: () => {
    return {
      cards: [
          {
            titleFrontText: "Hydrodips",
            frontImage: require("../assets/contentCards/hydrodips.jpg"),
            titleBackText: "Uniquely Yours",
            bodyBackText: "Hydro dipping begins with spray painting a surface of water, followed by dipping a clay pot into the water. Hydro dipping creates fluid patterns giving each pot a unique finish of delightful colour blending. Each pot is then lacquer sealed to promote water rentention and the soil’s nutritional balance.",
          },
          {
            titleFrontText: "Acrylic Pour",
            frontImage: require("../assets/contentCards/pours.jpg"),
            titleBackText: "Make It Rain",
            bodyBackText: "Acrylic pours use high pigmented paint, pouring medium, and silicone to create fluid art with vivid colours and designs. The pot is then sealed, making it waterproof to preserve product integrity while promoting soil balance."
          },
          {
            titleFrontText: "Decals - Logo Transfers",
            frontImage: require("../assets/contentCards/logo.jpg"),
            titleBackText: "Express Yourself",
            bodyBackText: "At Donna Grove we are able to transfer your logo onto any of our pots. We welcome your custom designs!" 
          },
          {
            titleFrontText: "Decoupage",
            frontImage: require("../assets/contentCards/decopage.jpg"),
            titleBackText: "Call It Collage",
            bodyBackText: "This technique includes incorporating texture into our designs. we produce a 3D effect with a variety of materials including preserved flora, giving life to each creation.",
          },
          {
            titleFrontText: "Premium Soil Mix",
            frontImage: require("../assets/contentCards/soilmix.jpg"),
            titleBackText: "Keep It Green",
            bodyBackText: "This pot ready mix is organic, fast draining, and nutrient rich. Our mix includes leca, coco choir, perlite, castings, and cinnamon. Our primary goals are simple: plant health and ease of care. We chose worm castings as a fertilizer because they are rich in essential nutrients and packed with beneficial microbes that contribute to a thriving soil environment."
          },
          {
            titleFrontText: "Plants",
            frontImage: require("../assets/contentCards/plants.jpg"),
            titleBackText: "Why We're Here",
            bodyBackText: "Donna Grove offers a variety of plants from common to rare origin. We routinely import species native to eastern Asia. Each plant is acclimated and quality assured with plant health as our outmost priority."
          }
      ]
    }
  }
}
</script>

<style scoped>
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .card {
    padding-bottom: 20px;
  }
</style>