<template>
  <v-footer padless class="">
    <!-- extra set of links that are hidden on sm and md screen sizes -->
    <v-col
      class="router-links-wrapper"
      cols="24"
      v-show="$vuetify.breakpoint.mdAndUp"
    >
      <router-link class="routerlink" to="/"
        ><p class="footer-main">Donna Grove</p></router-link
      >
      <router-link class="routerlink" to="/plants"
        ><p class="footer-secondary" style="display:none">Plants</p></router-link
      >
      <router-link class="routerlink" style="display:none" to="pots"
        ><p class="footer-secondary">Pots</p></router-link
      >
    </v-col>
    <v-col v-show="$vuetify.breakpoint.mdAndUp">
      <div class="contacts-svg-big">
        <svg
          height="275"
          width="400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <clipPath id="clip0">
              <rect width="450" height="275" fill="white" />
            </clipPath>
          </defs>
          <g clip-path="url(#clip0)">
            <path
              d="M146.352 250.554L140.244 200.195L140.239 200.117C140.241 198.663 140.785 197.268 141.752 196.24C142.718 195.211 144.029 194.633 145.396 194.631H204.991C206.358 194.633 207.669 195.211 208.635 196.24C209.602 197.268 210.146 198.663 210.148 200.117L205.704 236.773L204.035 250.554C204.024 252.001 203.475 253.386 202.51 254.406C201.544 255.425 200.239 255.998 198.878 256H151.509C150.148 255.998 148.843 255.425 147.877 254.406C146.911 253.386 146.363 252.001 146.352 250.554ZM204.991 195.85H145.396C144.339 195.851 143.325 196.296 142.574 197.088C141.823 197.88 141.396 198.955 141.385 200.08L145.82 236.617L147.493 250.435L147.498 250.513C147.499 251.645 147.922 252.73 148.674 253.53C149.426 254.33 150.445 254.78 151.509 254.781H198.878C199.942 254.78 200.961 254.33 201.713 253.53C202.465 252.73 202.888 251.645 202.889 250.513L209.002 200.08C208.991 198.955 208.564 197.88 207.813 197.088C207.062 196.296 206.048 195.851 204.991 195.85V195.85Z"
              fill="#3F3D56"
            />
            <path
              d="M175.194 209.872C192.916 209.872 207.283 207.324 207.283 204.182C207.283 201.039 192.916 198.492 175.194 198.492C157.471 198.492 143.104 201.039 143.104 204.182C143.104 207.324 157.471 209.872 175.194 209.872Z"
              fill="#FFB29A"
            />
            <path
              d="M172.416 201.621C176.85 190.053 179.11 177.672 179.068 165.179C179.058 152.681 176.834 140.297 172.514 128.675C170.081 122.16 166.966 115.958 163.226 110.187C162.703 109.38 163.94 108.62 164.459 109.42C171.196 119.899 175.93 131.685 178.39 144.108C180.872 156.463 181.155 169.204 179.224 181.67C178.14 188.651 176.317 195.479 173.792 202.025C173.448 202.916 172.066 202.526 172.416 201.621V201.621Z"
              fill="#CCCCCC"
            />
            <path
              d="M178.341 146.144C180.288 143.9 180.819 140.776 180.803 137.816C180.785 134.301 179.973 130.859 179.818 127.354C179.678 124.218 180.125 121.046 181.848 118.424C183.33 116.167 185.668 114.43 188.352 114.59C194.075 114.931 196.739 121.934 197.565 127.048C198.681 133.694 197.57 140.545 194.428 146.411C191.286 152.276 186.31 156.784 180.366 159.149C179.507 159.498 179.135 158.03 179.987 157.685C185.234 155.559 189.683 151.665 192.655 146.597C195.627 141.53 196.96 135.567 196.449 129.619C196.273 126.688 195.552 123.823 194.327 121.191C193.229 118.981 191.521 116.788 189.123 116.223C186.76 115.666 184.544 117.06 183.179 119.044C181.457 121.545 181.098 124.569 181.257 127.594C181.597 134.06 184.058 141.792 179.351 147.218C178.726 147.938 177.719 146.861 178.341 146.144V146.144Z"
              fill="#CCCCCC"
            />
            <path
              d="M177.229 173.954C172.389 170.594 171.906 164.022 171.296 158.487C170.95 155.354 170.449 152.023 168.642 149.424C167.155 147.287 164.635 145.45 162.025 145.948C159.724 146.386 158.168 148.407 157.322 150.605C156.433 153.153 156.09 155.879 156.319 158.586C156.674 164.75 158.941 170.616 162.765 175.262C164.64 177.625 166.849 179.663 169.314 181.302C171.842 182.917 174.665 183.941 177.594 184.304C178.495 184.418 178.506 185.938 177.594 185.823C174.815 185.495 172.118 184.62 169.64 183.243C167.224 181.84 165.027 180.046 163.13 177.924C158.792 173.309 155.976 167.325 155.101 160.865C154.663 157.923 154.761 154.919 155.388 152.017C155.998 149.488 157.217 146.952 159.319 145.486C160.447 144.747 161.75 144.365 163.076 144.384C164.401 144.403 165.693 144.823 166.802 145.595C169.366 147.255 170.981 150.016 171.8 153.032C172.805 156.733 172.734 160.629 173.476 164.381C174.098 167.532 175.315 170.814 177.949 172.643C178.716 173.175 178.001 174.491 177.229 173.955V173.954Z"
              fill="#CCCCCC"
            />
            <path
              d="M278.079 220.385H242.551C241.495 220.383 240.481 219.939 239.73 219.148C238.979 218.357 238.552 217.282 238.54 216.158L233.956 178.32C233.957 177.189 234.38 176.104 235.132 175.304C235.884 174.504 236.904 174.054 237.967 174.053H282.663C283.726 174.054 284.746 174.504 285.498 175.304C286.25 176.104 286.673 177.189 286.674 178.32L286.669 178.398L282.09 216.158C282.078 217.282 281.651 218.357 280.9 219.148C280.149 219.939 279.135 220.383 278.079 220.385V220.385ZM235.102 178.283L239.686 216.117C239.687 216.925 239.989 217.7 240.526 218.271C241.063 218.843 241.792 219.164 242.551 219.165H278.079C278.838 219.164 279.566 218.843 280.104 218.271C280.641 217.7 280.943 216.925 280.944 216.117L280.948 216.039L285.528 178.283C285.517 177.481 285.211 176.716 284.675 176.153C284.139 175.589 283.416 175.273 282.663 175.272H237.967C237.214 175.273 236.491 175.589 235.955 176.153C235.419 176.716 235.112 177.481 235.102 178.283V178.283Z"
              fill="#3F3D56"
            />
            <path
              d="M260.315 185.636C273.607 185.636 284.382 183.725 284.382 181.369C284.382 179.012 273.607 177.101 260.315 177.101C247.023 177.101 236.248 179.012 236.248 181.369C236.248 183.725 247.023 185.636 260.315 185.636Z"
              fill="#FFB29A"
            />
            <path
              d="M260.967 176.997C257.474 167.884 255.692 158.132 255.72 148.29C255.728 138.445 257.479 128.689 260.881 119.533C262.799 114.402 265.254 109.518 268.201 104.972C268.83 104.001 267.34 103.085 266.716 104.049C261.388 112.341 257.641 121.665 255.686 131.492C253.715 141.279 253.48 151.372 254.994 161.251C255.85 166.818 257.298 172.264 259.309 177.483C259.724 178.557 261.388 178.087 260.967 176.997V176.997Z"
              fill="#CCCCCC"
            />
            <path
              d="M256.207 133.011C252.697 128.917 255.082 122.561 255.146 117.744C255.265 115.301 254.611 112.885 253.287 110.883C252.051 109.139 250.17 107.841 248.048 107.983C243.408 108.294 241.207 113.844 240.524 118.03C239.615 123.367 240.499 128.874 243.021 133.588C245.543 138.301 249.542 141.921 254.319 143.815C255.353 144.237 255.8 142.47 254.776 142.052C250.7 140.393 247.245 137.364 244.935 133.425C242.625 129.486 241.587 124.853 241.978 120.229C242.11 117.918 242.682 115.659 243.659 113.59C244.509 111.912 245.833 110.211 247.693 109.858C249.538 109.509 251.187 110.753 252.152 112.334C253.381 114.348 253.535 116.698 253.369 119.038C253.006 124.144 251.331 130.033 254.992 134.304C255.739 135.176 256.952 133.879 256.207 133.011V133.011Z"
              fill="#CCCCCC"
            />
            <path
              d="M257.028 155.485C260.707 152.901 261.331 148.065 261.781 143.774C262.043 141.268 262.324 138.568 263.647 136.405C264.699 134.687 266.565 133.136 268.593 133.35C272.647 133.779 273.279 139.655 273.115 142.979C272.857 147.788 271.1 152.37 268.123 156C265.087 159.708 261.264 162.481 256.591 163.08C255.506 163.219 255.493 165.049 256.591 164.909C265.923 163.713 273.35 154.807 274.635 145.096C275.196 140.861 274.945 135.158 271.251 132.49C270.364 131.886 269.338 131.552 268.286 131.526C267.233 131.499 266.194 131.781 265.281 132.34C263.238 133.601 261.723 135.643 261.044 138.05C260.116 141.023 260.177 144.192 259.622 147.242C259.163 149.761 258.261 152.431 256.161 153.906C255.242 154.552 256.102 156.136 257.028 155.485H257.028Z"
              fill="#CCCCCC"
            />
            <path
              d="M272.338 104.985C267.578 104.985 262.925 103.484 258.968 100.67C255.01 97.8569 251.925 93.858 250.103 89.1795C248.282 84.5009 247.805 79.3527 248.734 74.386C249.663 69.4192 251.955 64.857 255.321 61.2762C258.686 57.6953 262.975 55.2568 267.643 54.2688C272.312 53.2809 277.151 53.788 281.548 55.7259C285.946 57.6638 289.705 60.9456 292.349 65.1562C294.994 69.3668 296.405 74.3171 296.405 79.3811C296.398 86.1694 293.86 92.6775 289.348 97.4776C284.836 102.278 278.719 104.978 272.338 104.985V104.985ZM272.338 54.9961C267.805 54.9961 263.374 56.4263 259.604 59.1057C255.835 61.7852 252.897 65.5936 251.162 70.0494C249.427 74.5052 248.974 79.4082 249.858 84.1384C250.742 88.8686 252.925 93.2136 256.131 96.6239C259.336 100.034 263.421 102.357 267.867 103.298C272.313 104.239 276.922 103.756 281.11 101.91C285.298 100.064 288.878 96.9388 291.396 92.9287C293.915 88.9186 295.259 84.204 295.259 79.3811C295.252 72.9161 292.835 66.7179 288.538 62.1464C284.241 57.5749 278.415 55.0034 272.338 54.9961V54.9961Z"
              fill="#3F3D56"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M288.071 79.4816C288.071 76.2831 287.209 73.1516 285.587 70.458C283.966 67.7644 281.653 65.6215 278.923 64.2829C276.192 62.9443 273.158 62.4662 270.18 62.905C267.202 63.3438 264.404 64.6813 262.118 66.7591C259.833 68.8368 258.154 71.5679 257.282 74.6289C256.41 77.6899 256.38 80.9527 257.197 84.0311C258.014 87.1094 259.642 89.8744 261.89 91.9986C264.138 94.1228 266.911 95.5173 269.88 96.0171V84.3215H265.884V79.4816H269.88V75.7933C269.88 71.5991 272.23 69.2804 275.823 69.2804C277.003 69.2979 278.18 69.4073 279.345 69.6077V73.7272H277.36C275.407 73.7272 274.796 75.0169 274.796 76.3425V79.4824H279.159L278.462 84.3222H274.796V96.0186C278.497 95.395 281.867 93.3869 284.301 90.3554C286.735 87.324 288.072 83.4682 288.071 79.4816V79.4816Z"
              fill="#68D391"
            />
            <path
              d="M110.83 248.117L117.546 245.924L112.792 217.322L102.88 220.558L110.83 248.117Z"
              fill="#D5B27F"
            />
            <path
              d="M132.411 248.33L111.304 255.222L108.801 246.546L121.753 242.317C123.916 241.611 126.254 241.847 128.253 242.975C130.252 244.103 131.747 246.029 132.411 248.33V248.33L132.411 248.33Z"
              fill="#2F2E41"
            />
            <path
              d="M53.5291 248.282L60.5537 248.281L63.8961 219.453L53.5273 219.454L53.5291 248.282Z"
              fill="#D5B27F"
            />
            <path
              d="M74.1035 255.22L52.0247 255.222L52.0243 246.146L65.5727 246.145C67.8351 246.145 70.0049 247.101 71.6047 248.803C73.2046 250.505 74.1034 252.814 74.1035 255.22V255.22V255.22Z"
              fill="#2F2E41"
            />
            <path
              d="M53.0246 164.716C52.4474 164.177 51.9882 163.511 51.6793 162.763C51.3704 162.016 51.2193 161.205 51.2367 160.389C51.2541 159.573 51.4395 158.77 51.7799 158.038C52.1202 157.307 52.6073 156.663 53.2068 156.153L58.6535 83.1842L69.7305 86.1285L60.8616 157.307C61.6 158.436 61.9239 159.813 61.7717 161.178C61.6195 162.543 61.0019 163.802 60.0359 164.715C59.0699 165.628 57.8226 166.132 56.5304 166.133C55.2381 166.133 53.9908 165.629 53.0246 164.716V164.716Z"
              fill="#D5B27F"
            />
            <path
              d="M75.2068 54.8828C82.9796 54.8828 89.2807 48.1792 89.2807 39.9098C89.2807 31.6404 82.9796 24.9368 75.2068 24.9368C67.4339 24.9368 61.1328 31.6404 61.1328 39.9098C61.1328 48.1792 67.4339 54.8828 75.2068 54.8828Z"
              fill="#D5B27F"
            />
            <path
              d="M62.9694 80.9397C59.7937 76.3016 59.6959 69.9135 63.1167 65.4771C65.3491 62.5819 69.0832 60.2651 75.3177 60.2651C91.9353 60.2651 98.2385 74.2865 98.2385 74.2865C98.2385 74.2865 105.115 87.6983 101.104 99.8908C97.0924 112.083 97.0924 113.303 97.0924 113.303L70.7335 110.864C70.7335 110.864 75.1803 98.7742 62.9694 80.9397Z"
              fill="#BCC2BC"
            />
            <path
              d="M54.8408 93.0764L60.5193 73.3118C60.8996 71.9884 61.5485 70.77 62.4201 69.7427C63.2917 68.7154 64.3647 67.9043 65.5633 67.3667C66.762 66.8291 68.057 66.5781 69.3568 66.6316C70.6566 66.685 71.9296 67.0414 73.0857 67.6758V67.6758C75.0914 68.781 76.6271 70.6563 77.3813 72.9215C78.1355 75.1867 78.0518 77.6723 77.1472 79.8742L69.928 97.3653L54.8408 93.0764Z"
              fill="#BCC2BC"
            />
            <path
              d="M96.5194 110.254L118.867 233.399L106.834 237.056L77.6097 155.976L64.4303 238.885L51.8238 240.714C51.8238 240.714 46.294 131.015 70.7335 110.864L96.5194 110.254Z"
              fill="#302D65"
            />
            <path
              d="M99.439 119.191C97.5635 119.192 95.7338 118.574 94.2021 117.423C92.6704 116.271 91.5121 114.643 90.8868 112.761L84.4336 93.6594L94.4522 88.5936L99.9512 106.454L127.968 105.009C128.118 104.756 128.284 104.514 128.465 104.285V104.285C129.012 103.603 129.681 103.044 130.434 102.642C131.188 102.239 132.009 102.002 132.85 101.943C133.691 101.884 134.534 102.006 135.33 102.3C136.127 102.594 136.859 103.055 137.485 103.655C138.664 104.813 139.39 106.401 139.518 108.102C139.646 109.804 139.166 111.495 138.175 112.837C137.646 113.536 136.992 114.115 136.25 114.539C135.508 114.964 134.693 115.227 133.854 115.311C133.015 115.395 132.168 115.299 131.365 115.029C130.561 114.759 129.816 114.32 129.175 113.739C129.018 113.596 128.868 113.444 128.727 113.284L101.226 119.006C100.637 119.129 100.039 119.191 99.439 119.191Z"
              fill="#D5B27F"
            />
            <path
              d="M80.8206 76.0569C80.6686 74.6825 80.8022 73.2893 81.212 71.9758C81.6217 70.6623 82.2977 69.4607 83.192 68.4557C84.0863 67.4507 85.1772 66.667 86.3876 66.1599C87.598 65.6529 88.8983 65.4348 90.1966 65.5212V65.5212C92.4508 65.6752 94.5656 66.7354 96.1122 68.4869C97.6588 70.2384 98.5214 72.55 98.5252 74.9532V94.0558L83.0903 96.5824L80.8206 76.0569Z"
              fill="#BCC2BC"
            />
            <path
              d="M78.3016 55.0189C76.1893 55.0091 74.5597 52.643 74.6544 50.3979C74.7491 48.1529 76.1664 46.1776 77.874 44.8547C79.5816 43.5317 81.5842 42.7259 83.4884 41.7533C85.3927 40.7806 87.2804 39.5674 88.4561 37.7003C89.1664 36.4783 89.5904 35.0927 89.6924 33.6601C89.7945 32.2274 89.5716 30.7896 89.0425 29.4677C87.9483 26.8238 86.0697 24.6344 83.6944 23.2348C80.1659 21.0374 75.9888 20.3348 71.9952 21.2669C68.0016 22.199 64.4846 24.6974 62.1447 28.2645L59.7334 35.2495C57.2764 38.3928 56.8867 43.0608 58.397 46.8199C59.9074 50.5791 63.1234 53.4074 66.7523 54.752C70.4342 56.0108 74.3756 56.1365 78.1217 55.1147"
              fill="#9C1CA7"
            />
            <path
              d="M65.2101 29.7319C64.4292 28.624 63.0758 27.9194 61.6766 27.5059C60.0556 27.0268 58.3315 26.8854 56.6387 27.0927C54.9459 27.3 53.3299 27.8503 51.9167 28.7009C50.5035 29.5515 49.3311 30.6794 48.4908 31.9967C47.6506 33.314 47.1651 34.7852 47.0723 36.2958C46.924 38.7086 47.7775 41.0681 48.279 43.4434C48.7805 45.8187 48.8802 48.4422 47.4008 50.473C46.2739 52.0197 44.3947 52.9936 42.5399 53.8251C40.9939 54.5182 39.3218 55.1686 37.5959 55.0305C35.8701 54.8924 34.1355 53.6823 34.1813 52.1489C33.6036 53.2484 33.2135 54.4177 33.0238 55.6187C32.9023 56.8246 33.2381 58.1259 34.2221 58.9668C35.6522 60.189 37.9182 60.0996 39.8837 59.8359C44.1386 59.2649 48.4471 58.1945 51.8086 55.8159C55.1702 53.4372 57.4007 49.5204 56.5155 45.7923C56.1457 44.235 55.2766 42.8133 54.5744 41.3484C53.8721 39.8834 53.3239 38.2811 53.6465 36.7155C53.8374 35.909 54.226 35.1493 54.7849 34.4903C55.3437 33.8312 56.0592 33.2888 56.8807 32.9013C58.5422 32.1365 60.4434 31.8813 62.2863 32.1756C63.4946 32.3511 65.0032 32.6237 65.7199 31.7448C65.9098 31.4664 66.0068 31.1461 65.9996 30.8209C65.9925 30.4957 65.8815 30.179 65.6795 29.9073C65.266 29.37 64.7395 28.9082 64.1287 28.5471"
              fill="#9C1CA7"
            />
            <path
              d="M156.039 111.516C151.28 111.516 146.626 110.015 142.669 107.201C138.711 104.388 135.626 100.389 133.805 95.7103C131.983 91.0318 131.506 85.8836 132.435 80.9169C133.364 75.9501 135.656 71.3879 139.022 67.8071C142.387 64.2263 146.676 61.7877 151.344 60.7997C156.013 59.8118 160.852 60.3188 165.249 62.2568C169.647 64.1947 173.406 67.4765 176.05 71.6871C178.695 75.8977 180.106 80.848 180.106 85.912C180.099 92.7003 177.561 99.2084 173.049 104.008C168.537 108.809 162.42 111.509 156.039 111.516V111.516ZM156.039 61.527C151.506 61.527 147.075 62.9572 143.305 65.6366C139.536 68.3161 136.598 72.1245 134.863 76.5803C133.129 81.0361 132.675 85.9391 133.559 90.6693C134.444 95.3995 136.627 99.7445 139.832 103.155C143.038 106.565 147.122 108.888 151.568 109.828C156.014 110.769 160.623 110.286 164.811 108.441C168.999 106.595 172.579 103.47 175.097 99.4596C177.616 95.4495 178.96 90.7349 178.96 85.912C178.953 79.447 176.536 73.2488 172.239 68.6773C167.942 64.1058 162.116 61.5343 156.039 61.527V61.527Z"
              fill="#3F3D56"
            />
            <path
              d="M162.594 73.2457C164.198 73.2508 165.734 73.9305 166.867 75.1366C168.001 76.3426 168.64 77.9768 168.645 79.6824V92.5545C168.64 94.26 168.001 95.8943 166.867 97.1003C165.734 98.3063 164.198 98.9861 162.594 98.9912H150.495C148.892 98.9861 147.356 98.3063 146.222 97.1003C145.089 95.8943 144.45 94.26 144.445 92.5545V79.6824C144.45 77.9769 145.089 76.3426 146.222 75.1366C147.356 73.9306 148.892 73.2508 150.495 73.2457H162.594L162.594 73.2457ZM162.594 70.6714H150.495C148.251 70.6784 146.1 71.63 144.513 73.3183C142.926 75.0067 142.032 77.2947 142.025 79.6824V92.5545C142.032 94.9422 142.926 97.2301 144.513 98.9185C146.1 100.607 148.251 101.559 150.495 101.565H162.594C164.839 101.559 166.989 100.607 168.576 98.9185C170.163 97.2302 171.058 94.9422 171.064 92.5545V79.6824C171.058 77.2947 170.163 75.0067 168.576 73.3183C166.989 71.6299 164.839 70.6783 162.594 70.6714L162.594 70.6714Z"
              fill="#68D391"
            />
            <path
              d="M164.41 79.6824C163.93 79.6814 163.47 79.4776 163.131 79.1159C162.792 78.7543 162.602 78.2643 162.603 77.7536C162.603 77.243 162.794 76.7534 163.134 76.3926C163.473 76.0317 163.934 75.829 164.414 75.829C164.894 75.829 165.354 76.0317 165.693 76.3926C166.033 76.7534 166.224 77.243 166.225 77.7536C166.225 78.2643 166.035 78.7543 165.696 79.1159C165.357 79.4776 164.897 79.6814 164.417 79.6824C164.415 79.6824 164.412 79.6824 164.41 79.6824Z"
              fill="#68D391"
            />
            <path
              d="M156.545 80.9691C157.502 80.9691 158.438 81.2711 159.234 81.8369C160.03 82.4027 160.65 83.2069 161.017 84.1478C161.383 85.0887 161.479 86.124 161.292 87.1229C161.105 88.1217 160.644 89.0392 159.967 89.7594C159.29 90.4795 158.428 90.9699 157.489 91.1686C156.55 91.3673 155.577 91.2653 154.693 90.8756C153.808 90.4859 153.052 89.8259 152.521 88.9791C151.989 88.1323 151.705 87.1368 151.705 86.1183C151.705 85.4421 151.83 84.7725 152.073 84.1478C152.317 83.5231 152.673 82.9554 153.122 82.4772C153.572 81.9991 154.105 81.6198 154.693 81.361C155.28 81.1023 155.909 80.9691 156.545 80.9691V80.9691ZM156.545 78.3948C155.109 78.3948 153.705 78.8478 152.512 79.6964C151.318 80.5451 150.387 81.7514 149.838 83.1626C149.288 84.5739 149.145 86.1269 149.425 87.6251C149.705 89.1233 150.396 90.4995 151.411 91.5797C152.427 92.6598 153.72 93.3954 155.129 93.6934C156.537 93.9915 157.997 93.8385 159.323 93.2539C160.65 92.6694 161.783 91.6794 162.581 90.4093C163.379 89.1392 163.805 87.6459 163.805 86.1183C163.805 85.1041 163.617 84.0997 163.252 83.1626C162.887 82.2256 162.352 81.3741 161.678 80.6569C161.004 79.9397 160.204 79.3708 159.323 78.9827C158.442 78.5945 157.498 78.3948 156.545 78.3948V78.3948Z"
              fill="#68D391"
            />
            <path
              d="M306.708 250.257L299.992 248.064L304.746 219.462L314.658 222.698L306.708 250.257Z"
              fill="#E6BD98"
            />
            <path
              d="M285.987 254.09H308.191L307.877 245.066H294.252C291.977 245.066 289.828 246.017 288.278 247.709C286.728 249.401 285.904 251.697 285.987 254.09V254.09H285.987Z"
              fill="#2F2E41"
            />
            <path
              d="M364.009 250.422L356.984 250.421L353.642 221.593L364.011 221.594L364.009 250.422Z"
              fill="#E6BD98"
              fill-opacity="0.9"
            />
            <path
              d="M343.435 257.361L365.513 257.362L365.514 248.286L351.965 248.286C349.703 248.286 347.533 249.242 345.933 250.943C344.334 252.645 343.435 254.954 343.435 257.361V257.361V257.361Z"
              fill="#2F2E41"
            />
            <path
              d="M364.514 166.856C365.091 166.317 365.55 165.651 365.859 164.903C366.168 164.156 366.319 163.345 366.301 162.529C366.284 161.713 366.099 160.91 365.758 160.178C365.418 159.447 364.931 158.803 364.331 158.293L358.885 85.3242L347.808 88.2685L356.677 159.448C355.938 160.576 355.614 161.953 355.766 163.318C355.919 164.683 356.536 165.942 357.502 166.855C358.468 167.768 359.715 168.272 361.008 168.273C362.3 168.273 363.547 167.769 364.514 166.856V166.856Z"
              fill="#E6BD98"
            />
            <path
              d="M342.331 57.023C334.558 57.023 328.257 50.3193 328.257 42.0499C328.257 33.7806 334.558 27.0769 342.331 27.0769C350.104 27.0769 356.405 33.7806 356.405 42.0499C356.405 50.3193 350.104 57.023 342.331 57.023Z"
              fill="#E6BD98"
              fill-opacity="0.9"
            />
            <path
              d="M354.569 83.0799C357.744 78.4418 357.842 72.0537 354.421 67.6173C352.189 64.7221 348.455 62.4053 342.22 62.4053C325.603 62.4053 319.3 76.4267 319.3 76.4267C319.3 76.4267 312.423 89.8384 316.435 102.031C320.446 114.223 320.446 115.443 320.446 115.443L346.805 113.004C346.805 113.004 342.358 100.914 354.569 83.0799Z"
              fill="#2C5D73"
            />
            <path
              d="M362.697 95.2166L357.019 75.452C356.639 74.1285 355.99 72.9101 355.118 71.8828C354.246 70.8555 353.173 70.0444 351.975 69.5068C350.776 68.9692 349.481 68.7183 348.181 68.7717C346.881 68.8251 345.609 69.1816 344.452 69.8159V69.8159C342.447 70.9211 340.911 72.7964 340.157 75.0616C339.403 77.3268 339.486 79.8124 340.391 82.0144L347.61 99.5054L362.697 95.2166Z"
              fill="#2C5D73"
            />
            <path
              d="M321.019 112.395L298.671 235.539L310.704 239.197L339.928 158.116L353.108 241.025L365.714 242.854C365.714 242.854 371.244 133.155 346.805 113.004L321.019 112.395Z"
              fill="#2F2E41"
            />
            <path
              d="M336.717 78.1972C336.869 76.8227 336.736 75.4295 336.326 74.1161C335.916 72.8026 335.24 71.6009 334.346 70.596C333.452 69.591 332.361 68.8073 331.15 68.3002C329.94 67.7931 328.64 67.5751 327.342 67.6615V67.6615C325.087 67.8154 322.973 68.8757 321.426 70.6271C319.879 72.3786 319.017 74.6903 319.013 77.0934V96.196L334.448 98.7226L336.717 78.1972Z"
              fill="#2C5D73"
            />
            <path
              d="M338.254 44.5534C338.358 45.4853 338.338 46.4706 338.749 47.2979C339.325 48.4563 340.565 48.9833 341.602 49.6726C345.804 52.4663 347.051 58.7519 345.712 63.8786C345.238 65.6928 344.49 67.5339 344.801 69.3887C345.334 72.5763 348.699 74.2288 351.689 74.5118C354.679 74.7949 357.809 74.2719 360.605 75.4491C365.302 77.4262 367.37 83.3705 371.362 86.6924C376.005 90.5561 382.401 90.3168 388.25 90.7639C390.412 90.9293 392.607 91.2102 394.731 90.7434C396.855 90.2766 398.951 88.8945 399.702 86.6996C400.76 83.6027 398.832 80.2447 396.592 77.9966C394.353 75.7486 391.619 73.8519 390.351 70.848C388.646 66.81 390.024 61.71 387.88 57.924C387.49 57.1558 386.877 56.549 386.136 56.1981C385.191 55.8153 384.144 56.0798 383.135 56.1172C379.23 56.2621 375.899 52.9001 374.085 49.1648C372.271 45.4295 371.5 41.2014 369.841 37.383C367.964 33.0617 364.836 29.2481 360.769 27.3725C358.387 26.2737 355.786 25.8701 353.299 25.0883C350.471 24.1996 347.772 22.8163 344.876 22.2403C341.979 21.6643 338.698 22.0445 336.581 24.2532C334.588 26.3321 334.007 29.5941 332.102 31.7668C331.382 32.588 330.457 33.2826 330.11 34.3495C329.289 36.8771 333.273 37.0903 334.601 38.1022C335.588 38.8454 336.423 39.8023 337.051 40.9127C337.68 42.023 338.09 43.2627 338.254 44.5534V44.5534Z"
              fill="#7C1908"
            />
            <path
              d="M319.099 121.331C320.975 121.332 322.804 120.714 324.336 119.563C325.868 118.411 327.026 116.783 327.651 114.902L334.104 95.7996L324.086 90.7339L318.587 108.594L290.57 107.149C290.42 106.896 290.254 106.654 290.073 106.425V106.425C289.526 105.743 288.857 105.184 288.104 104.782C287.35 104.38 286.529 104.142 285.688 104.083C284.847 104.025 284.004 104.146 283.208 104.44C282.412 104.734 281.679 105.195 281.053 105.795C279.874 106.953 279.148 108.541 279.02 110.243C278.892 111.944 279.372 113.635 280.363 114.977C280.892 115.676 281.546 116.255 282.288 116.68C283.031 117.105 283.845 117.367 284.684 117.451C285.523 117.535 286.37 117.439 287.174 117.169C287.977 116.899 288.722 116.46 289.363 115.879C289.52 115.736 289.67 115.584 289.812 115.425L317.312 121.146C317.901 121.269 318.499 121.331 319.099 121.331Z"
              fill="#E5BD98"
            />
          </g>
          <rect
            x="149.118"
            y="75.277"
            width="0.287"
            height="0.287"
            style="fill: rgb(216, 216, 216)"
          />
          <a
            target="_blank"
            rel="noreferrer"
            xlink:href="https://www.instagram.com/donna_grove_/"
          >
            <rect
              x="123.547"
              y="54.303"
              width="62.635"
              height="58.325"
              style="
                fill: rgb(216, 216, 216);
                stroke-width: 0px;
                stroke-opacity: 0;
                fill-opacity: 0;
              "
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            xlink:href="https://www.facebook.com/Donnagrove.paintedpots"
          >
            <rect
              x="242.209"
              y="49.131"
              width="62.923"
              height="66.945"
              style="fill: rgb(216, 216, 216); fill-opacity: 0"
            />
          </a>
        </svg>
      </div>
    </v-col>
    <v-col
      class="contacts-svg-small"
      cols="12"
      align="center"
      justify="center"
      v-show="$vuetify.breakpoint.smAndDown"
    >
      <div>
        <svg width="350" height="196" viewBox="0 0 350 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M121.972 190.883L117.355 152.518L117.352 152.459C117.353 151.35 117.764 150.288 118.495 149.504C119.226 148.721 120.216 148.28 121.25 148.279H166.3C167.333 148.28 168.324 148.721 169.055 149.504C169.786 150.288 170.197 151.35 170.198 152.459L166.839 180.384L165.578 190.883C165.569 191.986 165.154 193.041 164.424 193.818C163.694 194.595 162.708 195.031 161.679 195.032H125.871C124.842 195.031 123.855 194.595 123.125 193.818C122.395 193.041 121.981 191.986 121.972 190.883ZM166.3 149.207H121.25C120.451 149.208 119.684 149.547 119.117 150.151C118.549 150.754 118.226 151.573 118.218 152.43L121.57 180.266L122.835 190.793L122.838 190.852C122.839 191.714 123.159 192.541 123.727 193.15C124.296 193.76 125.067 194.103 125.871 194.104H161.679C162.483 194.103 163.254 193.76 163.822 193.15C164.391 192.541 164.71 191.714 164.711 190.852L169.332 152.43C169.324 151.573 169.001 150.754 168.433 150.151C167.866 149.547 167.099 149.208 166.3 149.207V149.207Z" fill="#3F3D56"/>
<path d="M143.775 159.89C157.172 159.89 168.032 157.949 168.032 155.555C168.032 153.161 157.172 151.22 143.775 151.22C130.378 151.22 119.518 153.161 119.518 155.555C119.518 157.949 130.378 159.89 143.775 159.89Z" fill="#FFB29A"/>
<path d="M141.675 153.604C145.027 144.791 146.736 135.359 146.704 125.841C146.696 116.319 145.015 106.885 141.749 98.0304C139.911 93.0671 137.555 88.3421 134.728 83.9452C134.333 83.3307 135.268 82.7515 135.66 83.3612C140.753 91.3442 144.332 100.324 146.191 109.788C148.067 119.201 148.281 128.907 146.822 138.404C146.002 143.723 144.625 148.925 142.716 153.912C142.455 154.591 141.411 154.293 141.675 153.604V153.604Z" fill="#CCCCCC"/>
<path d="M146.154 111.339C147.625 109.629 148.027 107.249 148.015 104.994C148.001 102.316 147.387 99.6942 147.27 97.024C147.165 94.6346 147.502 92.218 148.804 90.2207C149.925 88.5014 151.693 87.178 153.721 87.2999C158.047 87.5599 160.062 92.8952 160.686 96.7906C161.529 101.854 160.69 107.074 158.314 111.542C155.939 116.011 152.178 119.445 147.685 121.247C147.035 121.513 146.754 120.394 147.398 120.131C151.364 118.512 154.727 115.545 156.974 111.685C159.221 107.824 160.228 103.281 159.842 98.7496C159.709 96.5165 159.164 94.334 158.238 92.3284C157.408 90.6455 156.117 88.9744 154.304 88.5436C152.518 88.1193 150.843 89.1815 149.81 90.693C148.509 92.5986 148.237 94.9023 148.358 97.207C148.615 102.133 150.475 108.023 146.917 112.157C146.445 112.706 145.683 111.886 146.154 111.339V111.339Z" fill="#CCCCCC"/>
<path d="M145.314 132.526C141.655 129.967 141.29 124.96 140.828 120.743C140.567 118.355 140.189 115.818 138.822 113.838C137.698 112.21 135.794 110.811 133.82 111.189C132.081 111.523 130.905 113.063 130.265 114.738C129.593 116.679 129.334 118.756 129.507 120.818C129.775 125.514 131.489 129.983 134.379 133.523C135.797 135.323 137.467 136.876 139.331 138.124C141.242 139.355 143.375 140.134 145.589 140.412C146.271 140.498 146.279 141.656 145.589 141.569C143.489 141.319 141.45 140.652 139.577 139.603C137.75 138.534 136.09 137.167 134.655 135.551C131.377 132.035 129.247 127.476 128.586 122.554C128.255 120.313 128.329 118.025 128.803 115.813C129.264 113.887 130.185 111.955 131.775 110.838C132.628 110.275 133.613 109.983 134.614 109.998C135.616 110.013 136.593 110.333 137.432 110.921C139.369 112.186 140.591 114.289 141.21 116.587C141.969 119.406 141.916 122.374 142.476 125.233C142.947 127.633 143.867 130.134 145.858 131.527C146.438 131.933 145.897 132.935 145.314 132.526V132.526Z" fill="#CCCCCC"/>
<path d="M221.549 167.899H194.693C193.894 167.898 193.128 167.559 192.56 166.957C191.992 166.354 191.669 165.536 191.661 164.679L188.195 135.853C188.196 134.991 188.516 134.164 189.084 133.555C189.653 132.945 190.424 132.602 191.227 132.601H225.015C225.819 132.602 226.589 132.945 227.158 133.555C227.726 134.164 228.046 134.991 228.047 135.853L228.043 135.912L224.581 164.679C224.573 165.536 224.25 166.354 223.682 166.957C223.114 167.559 222.348 167.898 221.549 167.899V167.899ZM189.062 135.824L192.527 164.648C192.528 165.263 192.756 165.854 193.162 166.289C193.568 166.724 194.119 166.969 194.693 166.97H221.549C222.123 166.969 222.674 166.724 223.08 166.289C223.486 165.854 223.714 165.263 223.715 164.648L223.719 164.588L227.18 135.824C227.173 135.213 226.941 134.63 226.536 134.201C226.131 133.772 225.584 133.531 225.015 133.53H191.227C190.658 133.531 190.111 133.772 189.706 134.201C189.301 134.63 189.07 135.213 189.062 135.824V135.824Z" fill="#3F3D56"/>
<path d="M208.122 141.426C218.17 141.426 226.315 139.97 226.315 138.175C226.315 136.379 218.17 134.924 208.122 134.924C198.074 134.924 189.929 136.379 189.929 138.175C189.929 139.97 198.074 141.426 208.122 141.426Z" fill="#FFB29A"/>
<path d="M208.615 134.844C205.974 127.902 204.627 120.472 204.648 112.974C204.654 105.473 205.978 98.0408 208.55 91.0654C210 87.1567 211.856 83.4356 214.083 79.9727C214.558 79.2324 213.433 78.5349 212.961 79.2695C208.933 85.5867 206.1 92.6898 204.622 100.176C203.132 107.633 202.955 115.322 204.099 122.849C204.747 127.09 205.841 131.238 207.362 135.215C207.675 136.033 208.933 135.675 208.615 134.844V134.844Z" fill="#CCCCCC"/>
<path d="M205.016 101.334C202.363 98.2146 204.166 93.3728 204.214 89.7029C204.304 87.8411 203.809 86.0007 202.809 84.476C201.874 83.1467 200.452 82.1584 198.849 82.2667C195.341 82.5034 193.677 86.7315 193.161 89.9208C192.474 93.9866 193.142 98.182 195.048 101.773C196.955 105.364 199.978 108.122 203.589 109.565C204.371 109.886 204.709 108.54 203.935 108.221C200.854 106.958 198.241 104.65 196.495 101.649C194.749 98.6483 193.964 95.1184 194.26 91.5956C194.359 89.835 194.792 88.1144 195.53 86.5384C196.173 85.2597 197.174 83.9635 198.58 83.695C199.975 83.4287 201.222 84.3764 201.951 85.581C202.88 87.1153 202.997 88.9062 202.871 90.6889C202.597 94.5783 201.33 99.0654 204.097 102.319C204.662 102.983 205.579 101.995 205.016 101.334V101.334Z" fill="#CCCCCC"/>
<path d="M205.637 118.456C208.417 116.487 208.889 112.803 209.229 109.533C209.427 107.624 209.64 105.568 210.64 103.92C211.435 102.611 212.846 101.429 214.379 101.592C217.444 101.919 217.921 106.396 217.797 108.928C217.602 112.592 216.274 116.082 214.024 118.848C211.729 121.673 208.839 123.786 205.306 124.242C204.486 124.347 204.476 125.742 205.306 125.635C212.361 124.724 217.975 117.939 218.947 110.541C219.37 107.314 219.18 102.969 216.388 100.937C215.718 100.476 214.942 100.222 214.146 100.202C213.351 100.182 212.565 100.397 211.875 100.822C210.331 101.784 209.186 103.339 208.672 105.173C207.97 107.438 208.017 109.852 207.597 112.176C207.25 114.095 206.568 116.129 204.981 117.253C204.286 117.745 204.936 118.952 205.637 118.456H205.637Z" fill="#CCCCCC"/>
<path d="M217.211 79.9826C213.612 79.9826 210.095 78.8386 207.103 76.6952C204.111 74.5518 201.779 71.5053 200.402 67.9409C199.025 64.3766 198.665 60.4545 199.367 56.6706C200.069 52.8867 201.802 49.411 204.346 46.6829C206.891 43.9549 210.132 42.0971 213.661 41.3444C217.191 40.5918 220.849 40.9781 224.173 42.4545C227.497 43.9309 230.339 46.4311 232.338 49.6389C234.337 52.8467 235.404 56.6181 235.404 60.4761C235.398 65.6477 233.48 70.6059 230.069 74.2628C226.658 77.9197 222.034 79.9767 217.211 79.9826V79.9826ZM217.211 41.8985C213.784 41.8985 210.434 42.9881 207.584 45.0294C204.735 47.0707 202.514 49.9721 201.203 53.3668C199.891 56.7614 199.548 60.4967 200.217 64.1004C200.885 67.7041 202.536 71.0143 204.959 73.6125C207.382 76.2106 210.469 77.9799 213.83 78.6967C217.191 79.4136 220.675 79.0457 223.841 77.6396C227.007 76.2335 229.713 73.8523 231.617 70.7973C233.521 67.7422 234.537 64.1504 234.537 60.4761C234.532 55.5507 232.705 50.8287 229.457 47.3459C226.209 43.8631 221.804 41.9041 217.211 41.8985V41.8985Z" fill="#3F3D56"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M229.104 60.5527C229.103 58.1158 228.452 55.7302 227.226 53.6781C226 51.626 224.252 49.9934 222.188 48.9736C220.124 47.9538 217.83 47.5895 215.579 47.9238C213.328 48.2581 211.213 49.2771 209.485 50.86C207.757 52.4429 206.488 54.5236 205.829 56.8556C205.17 59.1876 205.147 61.6734 205.765 64.0186C206.382 66.3638 207.613 68.4703 209.312 70.0886C211.011 71.707 213.107 72.7694 215.352 73.1501V64.2399H212.332V60.5527H215.352V57.7427C215.352 54.5474 217.128 52.7809 219.845 52.7809C220.737 52.7942 221.627 52.8776 222.507 53.0303V56.1687H221.007C219.53 56.1687 219.068 57.1512 219.068 58.1611V60.5532H222.367L221.84 64.2404H219.068V73.1513C221.866 72.6762 224.414 71.1463 226.254 68.8368C228.093 66.5273 229.104 63.5898 229.104 60.5526V60.5527Z" fill="#68D391"/>
<path d="M95.1201 189.027L100.197 187.356L96.6033 165.565L89.1104 168.031L95.1201 189.027Z" fill="#D5B27F"/>
<path d="M111.434 189.189L95.4778 194.439L93.5859 187.83L103.377 184.608C105.012 184.07 106.779 184.25 108.29 185.109C109.801 185.969 110.932 187.436 111.434 189.189V189.189L111.434 189.189Z" fill="#2F2E41"/>
<path d="M51.804 189.152L57.1142 189.152L59.6409 167.189L51.8027 167.19L51.804 189.152Z" fill="#D5B27F"/>
<path d="M67.3564 194.438L50.6662 194.439L50.6659 187.525L60.9076 187.525C62.6179 187.525 64.2581 188.253 65.4675 189.55C66.6769 190.846 67.3564 192.605 67.3564 194.438V194.438V194.438Z" fill="#2F2E41"/>
<path d="M51.4229 125.488C50.9866 125.078 50.6394 124.57 50.4059 124.001C50.1724 123.431 50.0582 122.814 50.0713 122.192C50.0845 121.57 50.2246 120.958 50.4819 120.401C50.7392 119.843 51.1074 119.353 51.5606 118.965L55.678 63.3735L64.0515 65.6166L57.3471 119.844C57.9054 120.704 58.1502 121.753 58.0352 122.793C57.9201 123.833 57.4533 124.792 56.723 125.487C55.9928 126.183 55.0499 126.567 54.073 126.567C53.0962 126.567 52.1532 126.183 51.4229 125.488V125.488Z" fill="#D5B27F"/>
<path d="M68.1908 41.8122C74.0666 41.8122 78.8299 36.705 78.8299 30.4051C78.8299 24.1051 74.0666 18.9979 68.1908 18.9979C62.315 18.9979 57.5518 24.1051 57.5518 30.4051C57.5518 36.705 62.315 41.8122 68.1908 41.8122Z" fill="#D5B27F"/>
<path d="M58.9403 61.6636C56.5397 58.13 56.4657 53.2633 59.0516 49.8835C60.7392 47.6778 63.562 45.9127 68.2748 45.9127C80.8367 45.9127 85.6016 56.5948 85.6016 56.5948C85.6016 56.5948 90.7996 66.8125 87.7674 76.1013C84.7352 85.3901 84.7352 86.319 84.7352 86.319L64.8095 84.4613C64.8095 84.4613 68.171 75.2507 58.9403 61.6636Z" fill="#BCC2BC"/>
<path d="M52.7959 70.9098L57.0885 55.8523C57.376 54.844 57.8665 53.9158 58.5253 53.1331C59.1842 52.3505 59.9954 51.7326 60.9015 51.323C61.8076 50.9134 62.7865 50.7222 63.7691 50.7629C64.7517 50.8036 65.714 51.0752 66.5879 51.5585V51.5585C68.1041 52.4005 69.265 53.8292 69.8351 55.5549C70.4053 57.2806 70.342 59.1743 69.6581 60.8518L64.2009 74.1773L52.7959 70.9098Z" fill="#BCC2BC"/>
<path d="M84.3022 83.9968L101.196 177.814L92.0992 180.6L70.0077 118.83L60.0448 181.994L50.5151 183.387C50.5151 183.387 46.3349 99.8133 64.8097 84.4613L84.3022 83.9968Z" fill="#302D65"/>
<path d="M86.5092 90.8051C85.0914 90.8059 83.7083 90.3352 82.5504 89.4579C81.3926 88.5806 80.5169 87.3399 80.0442 85.9067L75.166 71.3539L82.7395 67.4946L86.8964 81.1013L108.075 80.0003C108.189 79.8075 108.314 79.6233 108.451 79.4492V79.4492C108.864 78.9293 109.371 78.5035 109.94 78.197C110.509 77.8905 111.13 77.7096 111.766 77.6648C112.402 77.62 113.039 77.7124 113.641 77.9364C114.243 78.1605 114.797 78.5116 115.27 78.9691C116.161 79.851 116.71 81.0608 116.807 82.3573C116.903 83.6538 116.541 84.9417 115.791 85.9643C115.392 86.4966 114.897 86.9377 114.336 87.2614C113.775 87.5851 113.159 87.7849 112.525 87.8489C111.89 87.9129 111.251 87.8399 110.643 87.6342C110.035 87.4285 109.473 87.0942 108.988 86.6512C108.869 86.5423 108.756 86.4268 108.649 86.3051L87.8601 90.664C87.4151 90.7576 86.9627 90.8048 86.5092 90.8051Z" fill="#D5B27F"/>
<path d="M72.4347 57.9436C72.3198 56.8964 72.4207 55.835 72.7305 54.8344C73.0403 53.8337 73.5512 52.9182 74.2273 52.1526C74.9034 51.387 75.728 50.7899 76.643 50.4036C77.558 50.0173 78.5409 49.8512 79.5223 49.917V49.917C81.2264 50.0343 82.825 50.842 83.9942 52.1764C85.1633 53.5107 85.8154 55.2719 85.8183 57.1027V71.6559L74.1504 73.5808L72.4347 57.9436Z" fill="#BCC2BC"/>
<path d="M70.5302 41.9158C68.9333 41.9084 67.7015 40.1058 67.773 38.3954C67.8446 36.685 68.916 35.1802 70.2069 34.1723C71.4977 33.1644 73.0116 32.5505 74.4511 31.8095C75.8906 31.0685 77.3175 30.1442 78.2063 28.7218C78.7432 27.7908 79.0638 26.7352 79.1409 25.6437C79.218 24.5523 79.0495 23.4569 78.6496 22.4498C77.8225 20.4356 76.4024 18.7676 74.6067 17.7013C71.9394 16.0272 68.7818 15.492 65.7629 16.2021C62.7439 16.9122 60.0853 18.8156 58.3165 21.5331L56.4937 26.8546C54.6364 29.2493 54.3417 32.8056 55.4835 35.6695C56.6253 38.5334 59.0563 40.6882 61.7996 41.7126C64.5828 42.6716 67.5623 42.7673 70.3941 41.9888" fill="#9C1CA7"/>
<path d="M60.6341 22.6511C60.0438 21.8071 59.0206 21.2703 57.9629 20.9552C56.7375 20.5903 55.4343 20.4825 54.1546 20.6405C52.8749 20.7984 51.6534 21.2177 50.585 21.8657C49.5167 22.5137 48.6305 23.3729 47.9953 24.3765C47.3601 25.3801 46.9931 26.5009 46.9229 27.6518C46.8108 29.49 47.456 31.2876 47.8351 33.0972C48.2142 34.9068 48.2897 36.9055 47.1713 38.4526C46.3194 39.631 44.8989 40.3729 43.4967 41.0064C42.3281 41.5344 41.064 42.0299 39.7594 41.9247C38.4548 41.8195 37.1435 40.8976 37.1782 39.7294C36.7414 40.567 36.4465 41.4579 36.3031 42.3728C36.2113 43.2916 36.4652 44.2829 37.209 44.9236C38.2901 45.8547 40.003 45.7866 41.4888 45.5857C44.7052 45.1507 47.9622 44.3352 50.5033 42.523C53.0445 40.7109 54.7306 37.7269 54.0614 34.8867C53.7819 33.7002 53.1249 32.6171 52.5941 31.5011C52.0632 30.385 51.6488 29.1643 51.8926 27.9715C52.037 27.3571 52.3307 26.7784 52.7532 26.2763C53.1757 25.7742 53.7165 25.3609 54.3375 25.0657C55.5935 24.483 57.0307 24.2886 58.4239 24.5128C59.3372 24.6466 60.4777 24.8542 61.0194 24.1846C61.163 23.9725 61.2363 23.7285 61.2309 23.4808C61.2255 23.233 61.1416 22.9917 60.9888 22.7848C60.6763 22.3754 60.2783 22.0236 59.8165 21.7485" fill="#9C1CA7"/>
<path d="M129.296 84.9582C125.697 84.9582 122.18 83.8141 119.188 81.6707C116.196 79.5273 113.864 76.4808 112.487 72.9165C111.11 69.3522 110.75 65.4301 111.452 61.6462C112.154 57.8623 113.887 54.3866 116.431 51.6585C118.976 48.9305 122.217 47.0727 125.746 46.32C129.275 45.5673 132.933 45.9536 136.258 47.43C139.582 48.9064 142.424 51.4066 144.423 54.6145C146.422 57.8223 147.489 61.5937 147.489 65.4517C147.483 70.6233 145.565 75.5815 142.154 79.2384C138.743 82.8953 134.119 84.9523 129.296 84.9582V84.9582ZM129.296 46.8741C125.869 46.8741 122.519 47.9636 119.669 50.005C116.82 52.0463 114.599 54.9477 113.288 58.3423C111.976 61.737 111.633 65.4723 112.302 69.076C112.97 72.6797 114.621 75.9899 117.044 78.588C119.467 81.1862 122.554 82.9555 125.915 83.6723C129.276 84.3892 132.76 84.0213 135.926 82.6152C139.092 81.2091 141.798 78.8279 143.702 75.7729C145.606 72.7178 146.622 69.126 146.622 65.4517C146.617 60.5263 144.79 55.8043 141.542 52.3215C138.293 48.8387 133.889 46.8796 129.296 46.8741V46.8741Z" fill="#3F3D56"/>
<path d="M134.251 55.8019C135.463 55.8058 136.624 56.3237 137.481 57.2425C138.338 58.1613 138.821 59.4063 138.825 60.7057V70.5122C138.821 71.8116 138.338 73.0566 137.481 73.9754C136.624 74.8942 135.463 75.4121 134.251 75.416H125.105C123.893 75.4121 122.732 74.8942 121.875 73.9754C121.018 73.0566 120.535 71.8116 120.531 70.5122V60.7057C120.535 59.4063 121.018 58.1613 121.875 57.2425C122.732 56.3237 123.893 55.8058 125.105 55.8019H134.251L134.251 55.8019ZM134.251 53.8407H125.105C123.408 53.846 121.783 54.571 120.583 55.8573C119.383 57.1436 118.707 58.8866 118.702 60.7057V70.5122C118.707 72.3313 119.383 74.0744 120.583 75.3606C121.783 76.6469 123.408 77.3719 125.105 77.3772H134.251C135.948 77.3719 137.573 76.647 138.773 75.3607C139.973 74.0744 140.649 72.3313 140.654 70.5122V60.7057C140.649 58.8866 139.973 57.1436 138.773 55.8573C137.573 54.571 135.948 53.846 134.251 53.8407L134.251 53.8407Z" fill="#68D391"/>
<path d="M135.623 60.7056C135.26 60.7048 134.912 60.5496 134.656 60.274C134.4 59.9985 134.256 59.6252 134.257 59.2362C134.257 58.8472 134.402 58.4742 134.658 58.1993C134.915 57.9243 135.263 57.7699 135.626 57.7699C135.989 57.7699 136.337 57.9243 136.593 58.1993C136.85 58.4742 136.995 58.8472 136.995 59.2362C136.995 59.6252 136.852 59.9985 136.595 60.274C136.339 60.5496 135.992 60.7048 135.629 60.7056C135.627 60.7056 135.625 60.7056 135.623 60.7056Z" fill="#68D391"/>
<path d="M129.677 61.6859C130.401 61.6859 131.108 61.916 131.71 62.3471C132.312 62.7781 132.781 63.3908 133.058 64.1076C133.335 64.8245 133.407 65.6132 133.266 66.3742C133.125 67.1352 132.776 67.8342 132.265 68.3828C131.753 68.9314 131.101 69.3051 130.391 69.4564C129.681 69.6078 128.946 69.5301 128.277 69.2332C127.609 68.9363 127.037 68.4335 126.635 67.7884C126.233 67.1432 126.019 66.3848 126.019 65.6089C126.019 65.0937 126.113 64.5836 126.297 64.1076C126.481 63.6317 126.75 63.1992 127.09 62.8349C127.43 62.4707 127.833 62.1817 128.277 61.9846C128.721 61.7874 129.197 61.6859 129.677 61.6859V61.6859ZM129.677 59.7247C128.592 59.7247 127.531 60.0698 126.628 60.7164C125.726 61.363 125.023 62.2819 124.607 63.3571C124.192 64.4323 124.083 65.6154 124.295 66.7568C124.507 67.8982 125.029 68.9467 125.797 69.7696C126.564 70.5925 127.542 71.1529 128.607 71.38C129.671 71.607 130.775 71.4905 131.778 71.0451C132.78 70.5998 133.637 69.8456 134.24 68.8779C134.843 67.9103 135.165 66.7727 135.165 65.6089C135.165 64.8362 135.023 64.071 134.748 63.3571C134.472 62.6432 134.068 61.9946 133.558 61.4482C133.048 60.9018 132.443 60.4683 131.778 60.1726C131.112 59.8769 130.398 59.7247 129.677 59.7247V59.7247Z" fill="#68D391"/>
<path d="M243.192 190.657L238.116 188.987L241.709 167.196L249.202 169.661L243.192 190.657Z" fill="#E6BD98"/>
<path d="M227.528 193.577H244.313L244.076 186.702H233.776C232.056 186.702 230.432 187.427 229.26 188.716C228.088 190.005 227.465 191.754 227.528 193.577V193.577H227.528Z" fill="#2F2E41"/>
<path d="M286.507 190.783L281.197 190.782L278.671 168.82L286.509 168.82L286.507 190.783Z" fill="#E6BD98" fill-opacity="0.9"/>
<path d="M270.955 196.069L287.645 196.07L287.646 189.156L277.404 189.155C275.694 189.155 274.053 189.884 272.844 191.18C271.635 192.477 270.955 194.235 270.955 196.069V196.069V196.069Z" fill="#2F2E41"/>
<path d="M286.89 127.118C287.326 126.708 287.673 126.2 287.907 125.631C288.14 125.061 288.254 124.444 288.241 123.822C288.228 123.2 288.088 122.589 287.831 122.031C287.573 121.474 287.205 120.983 286.752 120.595L282.634 65.0039L274.261 67.247L280.965 121.474C280.407 122.334 280.162 123.383 280.277 124.423C280.392 125.463 280.859 126.422 281.589 127.118C282.32 127.813 283.263 128.198 284.239 128.198C285.216 128.198 286.159 127.814 286.89 127.118V127.118Z" fill="#E6BD98"/>
<path d="M270.121 43.4427C264.245 43.4427 259.482 38.3355 259.482 32.0356C259.482 25.7356 264.245 20.6284 270.121 20.6284C275.996 20.6284 280.76 25.7356 280.76 32.0356C280.76 38.3355 275.996 43.4427 270.121 43.4427Z" fill="#E6BD98" fill-opacity="0.9"/>
<path d="M279.371 63.2941C281.772 59.7605 281.846 54.8938 279.26 51.5139C277.572 49.3083 274.75 47.5432 270.037 47.5432C257.475 47.5432 252.71 58.2253 252.71 58.2253C252.71 58.2253 247.512 68.443 250.544 77.7318C253.576 87.0206 253.576 87.9495 253.576 87.9495L273.502 86.0918C273.502 86.0918 270.141 76.8811 279.371 63.2941Z" fill="#2C5D73"/>
<path d="M285.517 72.5403L281.224 57.4828C280.937 56.4745 280.446 55.5463 279.787 54.7636C279.128 53.981 278.317 53.3631 277.411 52.9535C276.505 52.5439 275.526 52.3527 274.543 52.3934C273.561 52.4341 272.599 52.7057 271.725 53.189V53.189C270.208 54.031 269.047 55.4597 268.477 57.1854C267.907 58.9111 267.971 60.8047 268.654 62.4823L274.112 75.8077L285.517 72.5403Z" fill="#2C5D73"/>
<path d="M254.01 85.6272L237.117 179.444L246.213 182.231L268.305 120.46L278.268 183.624L287.797 185.017C287.797 185.017 291.978 101.444 273.503 86.0916L254.01 85.6272Z" fill="#2F2E41"/>
<path d="M265.877 59.5742C265.992 58.5271 265.891 57.4657 265.581 56.465C265.271 55.4643 264.76 54.5488 264.084 53.7832C263.408 53.0176 262.584 52.4205 261.669 52.0342C260.754 51.6479 259.771 51.4818 258.789 51.5476V51.5476C257.085 51.6649 255.486 52.4726 254.317 53.807C253.148 55.1413 252.496 56.9025 252.493 58.7333V73.2865L264.161 75.2114L265.877 59.5742Z" fill="#2C5D73"/>
<path d="M267.039 33.9428C267.117 34.6528 267.102 35.4034 267.413 36.0337C267.848 36.9162 268.785 37.3177 269.569 37.8429C272.746 39.9713 273.688 44.7599 272.676 48.6656C272.318 50.0477 271.753 51.4504 271.988 52.8635C272.391 55.2919 274.934 56.5508 277.195 56.7665C279.455 56.9822 281.821 56.5837 283.935 57.4806C287.485 58.9868 289.048 63.5154 292.066 66.0462C295.576 68.9898 300.411 68.8075 304.832 69.1481C306.467 69.274 308.126 69.4881 309.732 69.1324C311.337 68.7768 312.922 67.7239 313.489 66.0517C314.29 63.6924 312.832 61.1341 311.139 59.4214C309.446 57.7087 307.379 56.2637 306.421 53.9752C305.132 50.8989 306.174 47.0135 304.553 44.1291C304.258 43.5439 303.795 43.0816 303.234 42.8143C302.52 42.5227 301.728 42.7242 300.966 42.7527C298.014 42.863 295.496 40.3017 294.125 37.456C292.753 34.6102 292.171 31.3891 290.917 28.4801C289.498 25.1879 287.133 22.2826 284.059 20.8536C282.258 20.0165 280.292 19.7091 278.411 19.1134C276.274 18.4364 274.234 17.3825 272.044 16.9437C269.855 16.5049 267.375 16.7945 265.774 18.4772C264.267 20.061 263.828 22.5461 262.388 24.2014C261.843 24.827 261.144 25.3562 260.883 26.169C260.262 28.0946 263.273 28.2571 264.277 29.028C265.023 29.5942 265.654 30.3232 266.129 31.1691C266.605 32.0151 266.914 32.9595 267.039 33.9428V33.9428Z" fill="#7C1908"/>
<path d="M252.559 92.4357C253.977 92.4365 255.36 91.9658 256.518 91.0885C257.676 90.2112 258.551 88.9705 259.024 87.5373L263.902 72.9845L256.329 69.1252L252.172 82.7319L230.993 81.6309C230.88 81.4381 230.754 81.254 230.617 81.0798V81.0798C230.204 80.5599 229.698 80.1341 229.128 79.8277C228.559 79.5212 227.938 79.3402 227.303 79.2954C226.667 79.2507 226.029 79.343 225.427 79.5671C224.826 79.7911 224.272 80.1422 223.799 80.5997C222.907 81.4817 222.358 82.6915 222.262 83.988C222.165 85.2844 222.528 86.5724 223.277 87.5949C223.677 88.1272 224.171 88.5683 224.732 88.892C225.294 89.2157 225.909 89.4155 226.544 89.4795C227.178 89.5436 227.818 89.4706 228.425 89.2648C229.033 89.0591 229.596 88.7249 230.081 88.2818C230.199 88.173 230.312 88.0574 230.42 87.9358L251.208 92.2946C251.653 92.3882 252.106 92.4354 252.559 92.4357Z" fill="#E5BD98"/>
</g>
          <a
            target="_blank"
            rel="noreferrer"
            xlink:href="https://www.instagram.com/donna_grove_/"
          >
            <rect
              x="123.547"
              y="60.303"
              width="62.635"
              height="58.325"
              style="
                fill: rgb(216, 216, 216);
                stroke-width: 0px;
                stroke-opacity: 0;
                fill-opacity: 0;
              "
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            xlink:href="https://www.facebook.com/Donnagrove.paintedpots"
          >
            <rect
              x="200.209"
              y="57.131"
              width="52.923"
              height="56.945"
              style="fill: rgb(216, 216, 216); fill-opacity: 0"
            />
          </a>
<defs>
<clipPath id="clip0">
<rect width="350" height="195.032" fill="white"/>
</clipPath>
</defs>
</svg>

      </div>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.contacts-svg-big {
  float: right;
}
.contacts-svg-small {
  margin: auto;
  padding: 10px;
}
.router-links-wrapper {
  display: flex;
  margin: auto;
  padding: 12px;
  justify-content: space-evenly;
  align-items: center;
}
.footer-main {
  font-family: "Playfair Display";
  font-style: "normal";
  font-weight: "normal";
  font-size: 40px;
  line-height: 60px;

  color: #374151;
}
.footer-secondary {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: "normal";
  font-size: 24px;
  line-height: 32px;

  color: #374151;
}
.routerlink {
  display: block;
  color: #374151;
  text-decoration: none;
}
</style>