<template>
<div style="background: linear-gradient(267.19deg, #9AE6B4 0%, #38A169 100%); width: 100%; height: 102%; display: flex;">
  <v-container style="text-align:center; align-items: center; padding-top: 20px;">
    <p class="infoText-big">{{headerText}}</p>
    <p class="infoText-small">{{descriptionText}}</p>
    <v-form class="formWrapper" v-model="formValid" v-show="!subscribed" action="/subscribe" method="POST">
      <v-text-field flat solo v-model="email" :rules="emailRules" type="text" name="email" class="emailInput" id="emailAddress" placeholder="email@address.com" />
      <input type="submit" value="Submit" :disabled="!formValid" class="subscribeBtn v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--large secondary p-6" @click="submit"/>
    </v-form>
  </v-container>
</div>
</template>

<script>
import { getBaseUrl } from '../../config/index';
import axios from 'axios';

export default {
  name: "MailingList",
  data: function() {
    return {
      email: '',
      subscribed: false,
      formValid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {
    headerText: function() {
      if(!this.subscribed) {
        return 'Join our newsletter'
      } else {
        return 'Thank you!'
      }
    },
    descriptionText: function() {
      if(!this.subscribed) {
        return 'Get access to our best deals, tips, and tricks'
      } else {
        return 'Expect to hear from us soon!'
      }
    }
  },
  methods: {
    submit: function(event) {
      event.preventDefault();
      console.log(getBaseUrl());
      let data = {
        email_address: this.email,
        js: true
      }

      const axiosInstance = axios.create({
        baseUrl: getBaseUrl(),
        timeout: 1000,
        headers: {
          "Content-Type": "application/json",
        }
      })

      axiosInstance.post(getBaseUrl() + 'api/subscribe', JSON.stringify(data))
        .then(res => {
          console.log(res);
          if(res.status == 200) {
            this.subscribed = true;
            this.$toasted.global.submit_success();
          } else {
            this.$toasted.global.my_app_error();
          }
        })
    }
  }
}
</script>

<style scoped>
.v-btn {
  text-transform: none;
  margin-bottom: 2px;
  margin-left: 8px;
}
.infoText-big {
  font-style: "normal";
  font-weight: "normal";
  font-size: 40px;
  line-height: 60px;
  color: #FFFFFF;
}
.infoText-small {
  font-style: "normal";
  font-weight: "normal";
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.emailInput{
  max-width: 700px;
  min-width: 250px;
  padding: 12px 15px;
  margin: 6px 8px;
  display: inline-block;
  border-radius: 6px;
  box-sizing: border-box;  
  height:42px;
}
.formWrapper{
  vertical-align:center;
  justify-content: space-evenly;
}
.subscribeBtn{
  display:inline-block;  
}

/* For mobile screens */
@media screen and (max-width: 400px) {
  .subscribeBtn {
    display:inline-block;  
    margin-top:30px;
  }
}
</style>