<template>
  <v-app-bar app color="#DCBD9E">
    <v-row justify="center">
      <router-link class="routerlink" to="/plants" style="display:none"><h4>Plants</h4></router-link>
      <router-link class="routerlink" to="/"><h2>Donna Grove</h2></router-link>
      <router-link class="routerlink" to="/pots" style="display:none"><h4>Pots</h4></router-link>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: "Appbar",
};
</script>

<style scoped>
v-row {
  display: flex;
}
h2 {
  padding-left: 44px;
  padding-right: 44px;
}
h4 {
  margin-top: 8px;
}
.routerlink {
  display: block;
  color: #374151;
  text-decoration: none;
}
</style>