<template>
  <v-card elevation="10">
    <v-carousel
    continuous
    hide-delimiter-background
    show-arrows-on-hover
    height="auto"
    >
      <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      >
        <v-img :src="item.src" aspect-ratio="1.77"></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require('../assets/carousel/1.jpg')
        },
        {
          src: require('../assets/carousel/5.jpg')
        },
                {
          src: require('../assets/carousel/6.jpg')
        },
        {
          src: require('../assets/carousel/7.jpg')
        },
        {
          src: require('../assets/carousel/8.jpg')
        },
        {
          src: require('../assets/carousel/9.jpg')
        }, 
        {
          src: require('../assets/carousel/10.jpg')
        }, 
      ]
    }
  }
}
</script>