<template>
  <v-app>
    <Appbar/>
    <v-main class="main-app">
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Appbar from './components/Appbar'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    Appbar,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
/* Default Font Color */
.main-app {
  color: #374151
}

</style>