<template>
  <div>
  <v-card 
  class="mx-auto"
  >
    <v-img 
      :src="frontImage"
      height="75%">
    </v-img>
    <v-card-text>
      <div class="text-h4 text--primary">{{titleFrontText}}</div>
    </v-card-text>
    <v-card-actions
      style="margin-left: 10px; margin-right:10px; padding-bottom: 15px">
      <v-btn
        color="primary"
        large
        :class="displayStyle"
        @click="reveal = true">
        Learn More
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card
      v-if="reveal"
      class="transition-fast-in-fast-out v-card--reveal"
      style="height: 100%;">
        <v-card-text>
          <div class="text-h4 text--primary" style="padding-bottom: 20px;">{{titleBackText}}</div>
          <div class="text--primary">{{bodyBackText}}</div>
        </v-card-text>
        <v-card-actions class="v-card--actions cardActions">
          <v-btn
            color="primary"
            large
            style="width:97.65%"
            class="v-card-button--show"
            @click="reveal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
  </div>
</template>

<script>
export default {
  name: "ContentCard",
  props: {
    titleFrontText: {
      type: String,
      required: true
    },
    frontImage: {
      required: true
    },
    titleBackText: {
      type: String,
      required: true
    },
    bodyBackText: {
      type: String,
      required: true
    }
  },
  watch: {
    reveal: function() {
      this.getDisplayStyle();
    }
  },
  data: function() {
    return {
      reveal: false,
      displayStyle: "v-card-button--show"
    }
  },
  methods: {
    getDisplayStyle: function() {
      if(this.reveal) {
        this.displayStyle = "v-card-button--hide"
      } else {
        this.displayStyle = "v-card-button--show"
      }
    }
  }
}
</script>

<style scoped>
  .cardActions {
    margin-left: 10px; 
    margin-right: 10px;  
    margin-bottom: 7px;
  }
  .v-card--reveal {
    bottom: 0;
    opacity: .80 !important;
    position: absolute;
  }
  .v-card--actions {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .v-card-button--hide {
    visibility:hidden;
  }
  .v-card-button--show {
    width: inherit;
  }
  .v-btn {
    letter-spacing: 1;
    text-indent: 1;
    text-transform: none;
    font-size: 1;
  }
  .v-card__text {
    font-size: 1.1rem;
  }

  /* For mobile screens */
@media screen and (max-width: 400px) {
  .v-card__text {
    font-size: 1rem;
  }
  .cardActions {
    margin-bottom: 7px;
  }
}

</style>