import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
      iconfont: 'mdiSvg',
  },
  treeShake: true,
  defaultAssets: {
    font: {
      family: 'Libre Baskerville'
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#006a2b',
        secondary: '#DCBD9E',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
});
