import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import titleMixin from './mixins/titleMixin'
import Toasted from 'vue-toasted'

Vue.config.productionTip = false

Vue.mixin(titleMixin)
Vue.use(Toasted, {
  iconPack: 'mdi'
})

Vue.toasted.register('submit_success', 'Submitted Successfully', {
  type : 'success',
  icon : 'check-bold',
  position: 'top-center',
  duration: 3000
})
Vue.toasted.register('my_app_error', 'Oops.. Something Went Wrong..', {
  type : 'error',
  icon : 'error_outline',
  position: 'top-center',
  duration: 3000
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
