import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Plants from './views/Plants'
import Pots from './views/Pots'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/plants',
            name: 'plants',
            component: Plants
        },
        {
            path: '/pots',
            name: 'pots',
            component: Pots
        },
        {
            path: "/:catchAll(.*)",
            name: "home",
            component: Home,
        }
    ]
})